const sections = [
  {
    title: 'Public Activities',
    imageUrl:
      'https://images.unsplash.com/photo-1543060534-6461d31592ad?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
    description: `<p>Verified users can create a public activity with an image, and extra
  details. While participants can RSVP on the same page easily, activity hosts
  can see the list of the participants.`
  },
  {
    title: 'Bookings Calendar',
    imageUrl:
      'https://s3.eu-central-1.amazonaws.com/skogen-reading-materials/landing-page/nodal-calendar.png',
    description: `<p>In a typical artist-run house where there are shared resources, 
    such as that nice big printer; a member want to be able to book it to work on her own
    private project, as well as see who have already booked it in
    her own community. </p>
    <p>
    Members can see in the calendar for all of those shared
    resources and reclaim the empty spots.</p>`
  },
  {
    title: 'Study Groups',
    imageUrl:
      'https://s3.eu-central-1.amazonaws.com/skogen-reading-materials/landing-page/nodal-group.jpg',
    description: `<p>One of the foundational aims of crafting this software has been to start and run study groups, 
    like book reading circles or research projects with recurring meetings.</p>
    <p>It's build with collaboration and cooperation in mind; promoting reflective, critical and playful gatherings.</p>`
  },
  {
    title: 'Chat/Discussions',
    imageUrl:
      'https://s3.eu-central-1.amazonaws.com/skogen-reading-materials/landing-page/nodal-communication.png',
    description: `<p>For any of the above mentioned features, users can chat specific
    to the topic (booking, study-group, public-activity or
    maintenance), on the same page they are, without having to leave
    the page.</p>`
  },
  {
    title: 'Shared documents',
    imageUrl:
      'https://images.unsplash.com/photo-1457694587812-e8bf29a43845?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80',
    description: `<p>For all the documents specific to instances (study groups,
      public activities etc.), one can utilise the common storage and easy
      access with upload/download features that work by just dropping files (pdf/jpg/png).</p>`
  },
  {
    title: 'Memberships',
    imageUrl:
      'https://s3.eu-central-1.amazonaws.com/skogen-reading-materials/landing-page/nodal-members.jpg',
    description: `<p>Admins can manage the memberships within their
    organisation, either it is based on paid memberships, or just
    pure love.</p>`
  },
  {
    title: 'Maintenance management (soon!)',
    imageUrl:
      'https://images.unsplash.com/photo-1459908676235-d5f02a50184b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
    description: `<p>Members can create posts indicating a required task to be done
    regarding the facilities/machines/rooms that are shared. This
    way, keeping up the resources shared ready to use becomes easier
    to manage. Anyone can create a need, anyone can subscribe to do
    it and confirm being done.</p>`
  },
  {
    title: 'Portfolio pages (soon!)',
    imageUrl:
      'https://images.unsplash.com/photo-1459908676235-d5f02a50184b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
    description: `<p>Since we aim to provide tools for artists who have a need to display their work and basic info such as bio and contact details, 
    we want to build it for you to make it easier to manage your online portfolio. Soon we're releasing our portfolio feature so that artists
    can display their works with ease.</p>`
  },
  {
    title: 'Want to try?',
    description: `<p>
    We are currently working on making it easy for organisations
    to adopt this.
  </p>
  <p>
    Meanwhile, if you want to test/use this software or propose a collaboration please get in
    touch with us: 
    <a href="mailto:emin@infinitesimals.space?subject=We're interested in Artistrun.Space">
      Send an email
    </a>
  </p>`
  }
];

export { sections };
